<template>
  <div class="Report">
    <h1 class="text-h5 grey--text text-uppercase">Relatórios</h1>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        class="mt-4"
        v-for="(card, index) in menuCards"
        :key="index"
      >
        <v-card class="mx-auto" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                {{ card.category }}
              </div>
              <v-list-item-title class="text-h5 mb-1">
                {{ card.title }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                card.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn color="primary" text @click="openReport(card)">
              Abrir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    name: 'reportList',

    mixins: [
      //
    ],

    components: {
      //
    },

    mounted() {
      //
    },

    data() {
      return {
        menuCards: [
          {
            category: 'Censura',
            title: 'LISTA POR OPERADORES',
            description: 'Relatório de censuras ordenado por Operadores.',
            routeName: 'CensuresOperator',
          },
          {
            category: 'Censura',
            title: 'CENSURAS RESTANTES',
            description: 'Relatório de censuras que faltam, separadas por dia.',
            routeName: 'CensuresRemaining',
          },
          {
            category: 'Censura',
            title: 'CENSURAS COM PROBLEMAS',
            description: 'Relatório de censuras que constam com problemas.',
            routeName: 'CensuresWithProblem',
          },
        ],
      };
    },

    computed: {
      //
    },

    watch: {
      //
    },

    methods: {
      openReport(card) {
        if (card.routeName) {
          this.$router.push({ name: card.routeName });
        }
      },
    },
  };
</script>

<style scoped>
  .Report {
    padding: 20px;
  }
  .caption-play {
    transform: translateY(-40px);
  }
</style>
